<template>
    <section class="section">
        <h2>アカウント設定</h2>

        <div class="row">
            <form class="col-md-8" @submit.prevent="save">
                <div class="mb-3">
                    <label class="form-label">氏名</label>
                    <input type="text" v-model="me.user_name" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">メールアドレス</label>
                    <input type="email" v-model="me.email" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">パスワード</label>
                    <input type="password" v-model="me.password" class="form-control" placeholder="変更する場合のみ入力">
                </div>
                <div>
                    <button class="btn btn-primary">保存する</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PagePreference',
    components: {

    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            me: {
                user_name: this.$store.state.user.auth.user_name,
                email: this.$store.state.user.auth.email,
                password: null,
            }
        }
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.put('/me', this.me)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
